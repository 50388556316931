//== POLYFILLS
import './js/polyfills/nodelist.prototype.array.polyfill';
// IE 11 Polyfills
import { polyfill } from 'es6-promise'; polyfill();
import 'whatwg-fetch';

import './styles.scss';
import 'url-search-params-polyfill';


//=== VENDOR
// jquery
import $ from 'jquery';
window.$ = window.jQuery = $;

// moment
import moment from 'moment';
window.moment = moment;

// popper.js
import 'popper.js';

// slick carousel
import 'slick-carousel';

import {initMainMenuSub, initMobileMenu} from './js/menu';
import {initLightGallery} from './js/lightgallery';
import {initTwitterProxy} from './js/twitter-proxy';
import {initInputNumberCtrl} from './js/input-number';
import {initSlickCarousel} from './js/slick-carousel';
import {initSearchAutocomplete} from './js/autocomplete';
import './js/cookie';
import './js/mixed/mixed';


//=== CUSTOM
// z.B. Video Stream Einzelseite zeigt reduzierten Satz an Elementen an
const isStandalonePage = !!document.querySelector('body[data-standalone-page]');

// DOM READY
$(() => {
    if (isStandalonePage) return;
    initMainMenuSub();
    initMobileMenu();
    initLightGallery();
    initTwitterProxy();
    initInputNumberCtrl();
    initSlickCarousel();
    initSearchAutocomplete();
});
