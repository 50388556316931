/*
 * Suche Autocomplete
 * https://github.com/trevoreyre/autocomplete
 * https://autocomplete.trevoreyre.com/#/javascript-component
 */

// import $ from 'jquery';
import Autocomplete from "@trevoreyre/autocomplete-js";

export function initSearchAutocomplete() {
    const hasAutocomplete = document.querySelector('.autocomplete');
    if (!hasAutocomplete) return;

    new Autocomplete('.autocomplete', {
        search: input => {
            const url = `https://www.brandenburg.de/boss/web/autoc.php?index=www.landtag.brandenburg.de&term=${encodeURI(input)}`
            // return $.ajax({
            //     url: url,
            //     dataType: "json",
            // });

            return new Promise(resolve => {
                // mind. 2 Zeichen
                if (input.length < 3) {
                    return resolve([])
                }

                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        resolve(data)
                    });
            });
        },
        debounceTime: 400
    })
}
