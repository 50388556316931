import $ from 'jquery';
import {Popover} from "bootstrap";

$(function () {
    /*
     * Warenkorb Notification zeigt Anzahl der Produkte im Warenkorb.
     * Falls es nichts im Warenkorb gibt, wird die Notification ausgeblendet bleiben.
     */
    const $ltWkWidget = $('#lt-warenkorb-notification');
    if ($ltWkWidget.length) {
        $.ajax({
            url: '/sixcms/detail.php?template=ltbrb_wk_widget_d&anzahl=1',
            cache: false
        }).done(function (data) {
            if (data && data !== '') {
                const anzahl = parseInt(data, 10);
                if (anzahl > 0) {
                    $ltWkWidget.find('.lt-warenkorb-badge').text(anzahl.toString());
                    $ltWkWidget.show().css({opacity: 1});
                }
            }
        });
    }

    /* in bundle packen
     * PolyFillURLSearchParams in index.js geladen
     * Paginierung Auswahl der Anzahl der Einträge erzeugt Seitenreload mit URL-Parameter max
     */
    const $ltPaginationMax = $('.lt-pagination-max');
    if ($ltPaginationMax.length) {
        $ltPaginationMax.on('change', function () {
            if (typeof URLSearchParams !== 'undefined') {
                const params = new URLSearchParams(window.location.search);
                params.set('max', $(this).val());

                // reload page
                window.location.search = params.toString();
            }
        });
    }

    /*
     * show hidden images
     */
    $("[data-expandable-elements]").on("click", function (e) {
        e.preventDefault();
        const target = $(this).attr("data-target");
        $(`${target} .hidden`).removeClass("hidden");
        $(this).hide();
    });

    /*
     * Popover Tooltips per mouseover
     * Wahlkreiskarte Wahlkreise im SVG
     */
    const $wk = $('.wk');
    if ($wk.length) {
        $wk.each(function () {
            new Popover(this, {
                placement: 'auto',
                trigger: 'hover',
                container: '.lt-wahlkreise__karte'
            });
        })
    }

    /*
     * Filter Auswahl bei einer Änderung Formular Absenden
     */
    $('.form-reload-onchange').on('change', function () {
        $(this).submit();
    });
});
