import $ from 'jquery';

export const defaultSlickOptions = {
    selector: '[data-slick-carousel]',
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
            },
        },
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
            },
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
            },
        },
    ],
};

export const keyvisualSlickOptions = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1500,
    fade: true,
    cssEase: 'linear',
};

export function initSlickCarousel(opts = defaultSlickOptions) {
    opts = {
        ...defaultSlickOptions,
        ...opts,
    };

    // init regular slick carousel
    if ($(opts.selector).length) {
        $(opts.selector).slick(opts);
    }

    // init keyvisual slider
    if ($('.keyvisual_inner').length) {
        $('.keyvisual_inner').slick(keyvisualSlickOptions);
    }

}
