const activeClassName = 'active';

const mainNav = {
    isOpen: false,
    mainNavEl: null
}

const menuMobile = {
    isOpen: false,
    toggleMenu: null,
    mainMenu: null
}

const searchMobile = {
    isOpen: false,
    toggleSearch: null,
    searchMobileEl: null,
    searchMobileInput: null
}

const searchDesktop = {
    isOpen: false,
    searchDesktopEl: null,
    searchDesktopInput: null
}

document.addEventListener('click', checkMenuAndSearchStates);

export function checkMenuAndSearchStates(e) {

    if(mainNav.isOpen) {
        if(!mainNav.mainNavEl.contains(e.target)) {
            mainNav.mainNavEl.querySelectorAll(`.${activeClassName}`).forEach(el => {
                el.classList.remove(activeClassName);
            });
            mainNav.isOpen = false;
        }
    }

    if(menuMobile.isOpen) {
        if(!menuMobile.toggleMenu.contains(e.target) && !menuMobile.mainMenu.contains(e.target)) {
            menuMobile.toggleMenu.classList.toggle(activeClassName, false);
            menuMobile.mainMenu.classList.toggle(activeClassName, false);
            menuMobile.isOpen = false;
        }
    }

    if(searchMobile.isOpen) {
        if(!searchMobile.toggleSearch.contains(e.target) && !searchMobile.searchMobileEl.contains(e.target)) {
            searchMobile.toggleSearch.classList.toggle(activeClassName, false);
            searchMobile.searchMobileEl.classList.toggle('hidden', true);
            searchMobile.searchMobileInput.value = '';
            searchMobile.isOpen = false;
        }
    }

    if(searchDesktop.isOpen) {
        if(!searchDesktop.searchDesktopEl.contains(e.target) && !e.target.classList.contains('autocomplete-result')) {
            searchDesktop.searchDesktopInput.classList.toggle(activeClassName, false);
            searchDesktop.searchDesktopEl.classList.toggle(activeClassName, false);
            searchDesktop.searchDesktopInput.value = ''; // clean up
            searchDesktop.isOpen = false;
        }
    }
}

/**
 * toggle submenu
 */
export function initMainMenuSub() {

    // dom ready abgewartet, befülle DOM Elemente
    mainNav.mainNavEl = document.querySelector('.main-nav');

    menuMobile.toggleMenu = document.querySelector('.toggle-menu');
    menuMobile.mainMenu = document.querySelector('.header-menu');

    searchMobile.toggleSearch = document.querySelector('.toggle-search');
    searchMobile.searchMobileEl = document.querySelector('.search-mobile');
    searchMobile.searchMobileInput = document.querySelector('.search-mobile-input');

    searchDesktop.searchDesktopEl = document.querySelector('.search-desktop');
    searchDesktop.searchDesktopInput = document.querySelector('.search-desktop-input');

    // early exit if not available
    if (!mainNav.mainNavEl) return;

    const mainNavLinks = mainNav.mainNavEl.querySelectorAll('.main-nav__link');

    const hideMainMenu = () => {
        mainNav.mainNavEl.querySelectorAll(`.${activeClassName}`).forEach(el => {
            el.classList.remove(activeClassName);
        });
    }

    mainNavLinks.forEach(mainNavLink => {
        mainNavLink.addEventListener('click', (e) => {
            if (e.target.classList.contains(activeClassName)) {
                e.target.classList.remove(activeClassName);
                mainNav.isOpen = false;
            } else {
                hideMainMenu();
                e.target.classList.add(activeClassName);
                mainNav.isOpen = true;
            }
        });
    });
}

/**
 * toggle main menu in mobile view
 */
export function initMobileMenu() {
    // Sprachwechsler Dropdown
    $('#dropdownLanguage').dropdown();

    // early exit if not available
    if (!menuMobile.toggleMenu || !menuMobile.mainMenu) return;

    menuMobile.toggleMenu.addEventListener('click', () => {

        // close search
        if (searchMobile.toggleSearch.classList.contains(activeClassName)) {
            searchMobile.toggleSearch.click();
        }

        const isActive = menuMobile.toggleMenu.classList.toggle(activeClassName);    // toggle icon
        menuMobile.mainMenu.classList.toggle(activeClassName, isActive);           // toggle main menu

        menuMobile.isOpen = isActive;
    });


    if (!searchMobile.toggleSearch || !searchDesktop.searchDesktopInput) return;

    // mobile search
    searchMobile.toggleSearch.addEventListener('click', () => {

        // close mobile menu
        if (menuMobile.toggleMenu.classList.contains(activeClassName)) {
            menuMobile.toggleMenu.click();
        }

        const isActive = searchMobile.toggleSearch.classList.toggle(activeClassName);    // toggle icon
        searchMobile.searchMobileEl.classList.toggle("hidden", !isActive);

        if (isActive) {
            searchMobile.searchMobileInput.focus()
            searchMobile.isOpen = true;
        } else {
            searchMobile.isOpen = false;
        }
    });

    // desktop search
    searchDesktop.searchDesktopInput.addEventListener('focus', () => {

        if(searchDesktop.searchDesktopInput.classList.contains(activeClassName)) {
            return;
        }
        const isActive = searchDesktop.searchDesktopInput.classList.toggle(activeClassName);    // toggle icon
        searchDesktop.searchDesktopEl.classList.toggle(activeClassName);

        if (isActive) {
            searchDesktop.isOpen = true;
        }
    });
}
