//=== LIGHTGALLERY
import 'lightgallery/scss/lightgallery-bundle.scss';
import '../../style/vendor/_custom.lightgallery.scss';
import lightGallery from 'lightgallery';
import Autoplay from 'lightgallery/plugins/autoplay';
import Thumbnail from 'lightgallery/plugins/thumbnail';
import FullScreen from 'lightgallery/plugins/fullscreen';
import Zoom from 'lightgallery/plugins/zoom';


export function initLightGallery() {

    const lightboxGalleries = document.querySelectorAll('[data-lightbox-gallery]');

    // early exit if not available
    if (!lightboxGalleries) return;

    lightboxGalleries.forEach((lightboxGallery) => {

        lightGallery(lightboxGallery, {
            licenseKey: '11C25184-849A4901-B551C5F7-9FF398E6',
            speed: 500,
            download: true,
            showCloseIcon: true,
            swipeToClose: true,
            plugins: [Autoplay, FullScreen, Thumbnail, Zoom]
        });

    });
}