export function initInputNumberCtrl() {
    // init
    const els = {
        decrements: document.querySelectorAll('.ctrl__button--decrement'),
        counter: {
            containers: document.querySelectorAll('.ctrl__counter'),
            nums: document.querySelectorAll('.ctrl__counter-num'),
            inputs: document.querySelectorAll('.ctrl__counter-input')
        },
        increments: document.querySelectorAll('.ctrl__button--increment')
        };
    var inputNumber = new inputNumberCtrl(els);
    inputNumber.ready();
}

function inputNumberCtrl(els) {

    var _this = this;
    this.counter = [];

    this.els = els;

    this.els.counter.inputs.forEach((input, idx) => {
        var count = 0;
        if(!isNaN(parseInt(input.value, 10))){
            count = parseInt(input.value);
        }
        if(!isNaN(parseInt(input.min, 10)) && (count < parseInt(input.min, 10))){
            count = parseInt(input.min, 10);
        }
        if(!isNaN(parseInt(input.max, 10)) && (count > parseInt(input.max, 10))){
            count = parseInt(input.max, 10);
        }
        _this.counter[idx] = count;
        _this.els.counter.nums[idx].innerText = count.toString();
    });

    this.decrement = function(idx) {
        var min = 0;
        if(_this.els.counter.inputs[idx].min) {
            min = parseInt(_this.els.counter.inputs[idx].min, 10);
        }
        var counter = _this.getCounter(idx);
        var nextCounter = (counter > min) ? --counter : counter;
        _this.setCounter(nextCounter, idx);
    };

    this.increment = function(idx) {
        var max = 999999999;
        if(_this.els.counter.inputs[idx].max) {
            max = parseInt(_this.els.counter.inputs[idx].max, 10);
        }
        var counter = _this.getCounter(idx);
        var nextCounter = (counter < max) ? ++counter : counter;
        _this.setCounter(nextCounter, idx);
    };

    this.getCounter = function(idx) {
        return _this.counter[idx];
    };

    this.setCounter = function(nextCounter, idx) {
        _this.counter[idx] = nextCounter;
    };

    this.debounce = function(callback, idx) {
        setTimeout(callback, 100, idx);
    };

    this.render = function(idx, hideClassName, visibleClassName) {
        _this.els.counter.nums[idx].classList.add(hideClassName);

        setTimeout(function() {
            _this.els.counter.nums[idx].innerText = _this.getCounter(idx);
            _this.els.counter.inputs[idx].value = _this.getCounter(idx);
            _this.els.counter.nums[idx].classList.add(visibleClassName);
        }, 100, idx);

        setTimeout(function() {
            _this.els.counter.nums[idx].classList.remove(hideClassName);
            _this.els.counter.nums[idx].classList.remove(visibleClassName);
        }, 1100, idx);
    };

    this.ready = function() {
        _this.els.decrements.forEach((decrement, idx) => {
            decrement.addEventListener('click', () => {
                _this.debounce(function(idx) {
                    _this.decrement(idx);
                    _this.render(idx, 'is-decrement-hide', 'is-decrement-visible');
                }, idx);
            });
        });

        _this.els.increments.forEach((increment, idx) => {
            increment.addEventListener('click', () => {
                _this.debounce(function(idx) {
                    _this.increment(idx);
                    _this.render(idx, 'is-increment-hide', 'is-increment-visible');
                }, idx);
            });
        });

        _this.els.counter.inputs.forEach((input, idx) => {
            input.addEventListener('input', e => {
                var parseValue = parseInt(e.target.value, 10);
                if (!isNaN(parseValue) && parseValue >= 0) {
                    _this.setCounter(parseValue, idx);
                    _this.render(idx);
                }
            });
        });

        _this.els.counter.inputs.forEach((input, idx) => {
            input.addEventListener('focus', () => {
                _this.els.counter.containers[idx].classList.add('is-input');
            });
        });

        _this.els.counter.inputs.forEach((input, idx) => {
            input.addEventListener('blur', e => {
                var parseValue = parseInt(e.target.value, 10);
                var min = 0;
                if(_this.els.counter.inputs[idx].min) {
                    min = parseInt(_this.els.counter.inputs[idx].min, 10);
                }
                var max = 9999999999;
                if(_this.els.counter.inputs[idx].max) {
                    max = parseInt(_this.els.counter.inputs[idx].max, 10);
                }
                if(parseValue < min) {
                    _this.setCounter(min, idx);
                }else if(parseValue > max) {
                    _this.setCounter(max, idx);
                }
                _this.els.counter.containers[idx].classList.remove('is-input');
                _this.render(idx);
            });
        });
    };
}
