import $ from 'jquery';

$(function () {
    function youtubeAccepted() {
        let cookie = getConsentCookie();
        return cookie && cookie.functional && cookie.functional.youtube;
    }

    function toggleVideo($el, show) {
        show = show === true;
        $el.each(function () {
            $(this).attr('src', show ? $(this).attr('data-src') : '')
                .css('display', show ? 'block' : 'none');
        });
    }

    // start / stop video on modal open / close
    $(document)
        .on('hide.bs.modal', '#sign-language-modal', function () {
            toggleVideo($(this).find('.youtube-video'), false);
        })
        .on('show.bs.modal', '#sign-language-modal', function () {
            if (youtubeAccepted()) toggleVideo($(this).find('.youtube-video'), true);
        });

    $('.dsgvo').addClass('loaded'); // sets opacity to 1 to prevent FOUC

    // disable accept-buttons in agb to
    // force the user to accept the TOS
    $('.agb button.accept').attr('disabled', true);

    $(document)

        // dsgvo check
        .on('click', '.dsgvo .accept', function (e) {
            e.preventDefault();

            let provider = $(this).closest('.dsgvo').attr('data-provider');

            switch (provider) {
                case 'youtube':
                    console.log('youtube');
                    updateConsentCookie('functional', 'youtube', true);
                    break;

                case 'threeqsdn':
                    console.log('three');
                    updateConsentCookie('functional', 'threeqsdn', true);
                    break;

                default:
                    console.log('no provider selected. add data-provider to .dsgvo wrapper');
            }
        })

        .on('click', '.agb .accept', function (e) {
            e.preventDefault();

            // collect players that need to be initialized
            const playerWrappers = document.getElementsByClassName('playerWrapper');

            $(this).closest('.agb').addClass('agb-accepted');

            if (!playerWrappers.length) return;

            let count = 0;

            const g = document.createElement('script');
            g.type = 'text/javascript';
            g.src = 'https://player.3qsdn.com/js3q.latest.js';
            g.onload = function () {
                const {js3q} = window;

                if (typeof js3q !== 'function') { return; }

                for (const playerWrapper of playerWrappers) {

                    // generate incrementing ids
                    const containerId = `sdnPlayer${++count}`;

                    // add generated container to wrapper element
                    const playerContainer = document.createElement('div');
                    playerContainer.id = containerId;

                    playerWrapper.appendChild(playerContainer);

                    const dataid = playerWrapper.getAttribute('data-dataid');

                    const threeQConfig = {
                        dataid,
                        container: containerId,
                        autoplay: false,
                    };

                    // handle branding
                    let position = playerWrapper.getAttribute('data-logo-position');

                    if (!position || !position.length) {
                        position = 'none';
                    }

                    // add branding only if there is a logo position
                    if (position !== 'none') {
                        threeQConfig.branding = {
                            position,
                            src: '/assets/static/img/logo_ltbb_wz.svg',
                        };
                    }

                    new js3q(threeQConfig);
                }

            };

            document.body.appendChild(g);
        })

        // agb checkbox
        .on('change', 'input[data-enable-accept-button]', function (e) {
            const checked = e.target.checked;
            const parent = $(this).closest('.agb');

            if (!parent) return;

            $(parent).find('button.accept').attr('disabled', !checked);
        });

    function createCookie(name, value, days) {
        let expires = '';
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/';
    }

    function removeCookie(name) {
        createCookie(name, '', -1); // reset cookie to expire yesterday
    }

    function getCookie(cookieName) {
        if (document.cookie.length > 0) {
            let c_start = document.cookie.indexOf(cookieName + '=');
            if (c_start !== -1) {
                c_start = c_start + cookieName.length + 1;
                let c_end = document.cookie.indexOf(';', c_start);
                if (c_end === -1) {
                    c_end = document.cookie.length;
                }
                return unescape(document.cookie.substring(c_start, c_end));
            }
        }
        return '';
    }

    /**
     *
     * @return {JSON}
     */
    function getConsentCookie() {
        let consent = getCookie('consent');
        return consent.length ? JSON.parse(consent) : {system: false, functional: false, statistic: false};
    }

    /**
     * Updates consent cookie and executes `init()` or `destroy()` function
     * if present in `providers`-Object
     */
    function updateConsentCookie(providerType, providerName, value) {
        let cookie = getConsentCookie();
        let provider = providers[providerType][providerName];
        if (provider) {
            value ? provider.init() : provider.destroy();
        }

        let updatedProvider = {};
        updatedProvider[providerName] = value;
        cookie[providerType] 										// if cookie[providerType] is false
            ? Object.assign(cookie[providerType], updatedProvider)	// Object.assign will not work
            : cookie[providerType] = updatedProvider;				// instead we need to assign the whole value

        createCookie('consent', JSON.stringify(cookie));
    }

    function saveConsentCookieByType(selection) {
        let selectedProviders = {};

        Object.keys(selection).forEach(function (selectedType) {
            if (providers.hasOwnProperty(selectedType)) {
                selectedProviders[selectedType] = {};

                // accept/decline all providers of that type
                Object.keys(providers[selectedType]).forEach(function (providerName) {
                    let doEnable = !!selection[selectedType];

                    selectedProviders[selectedType][providerName] = doEnable;
                    providers[selectedType][providerName].enabled = doEnable;

                    if (doEnable) providers[selectedType][providerName].init();
                });
            }
        });

        createCookie('consent', JSON.stringify(selectedProviders), 365);
    }

    // noinspection JSUnresolvedReference
    $(function () {
        /**
         * JSON
         * @returns {{}}
         */
        function getCheckboxValues() {
            let values = {};
            $('.ckb [type=checkbox]').each(function (_, v) {
                let categoryName = $(v).val();
                values[categoryName] = $(v).prop('checked');
            });
            return values;
        }

        $('[data-accept-selected-cookies]').on('click', function (e) {
            e.preventDefault();
            saveConsentCookieByType(getCheckboxValues());
            $('.ckb').remove();
        });

        $('[data-accept-all-cookies]').on('click', function (e) {
            e.preventDefault();
            saveConsentCookieByType({system: true, functional: true, statistic: true});
            $('.ckb').remove();
        });

        $('[data-reject-cookies]').on('click', function (e) {
            e.preventDefault();
            saveConsentCookieByType({system: true, functional: false, statistic: false});
            $('.ckb').remove();
        });

        $('#deleteCookie').on('click', function (e) {
            e.preventDefault();
            removeCookie('consent');
            location.reload();
        });

        $('#getCookie').on('click', function (e) {
            e.preventDefault();
            let consent = getConsentCookie();
            console.log(consent);
        });

        function initYouTube() {
            console.log('init youtube');

            $('.dsgvo[data-provider="youtube"]').addClass('dsgvo-accepted');
            toggleVideo($('.youtube-video'), true);
        }

        function initMatomo() {
            console.log('init matomo');

            if (typeof _paq !== 'undefined') {
                let u = 'https://piwik.brandenburg.de/';

                _paq.push(['setTrackerUrl', u + 'piwik.php']);
                _paq.push(['setSiteId', '16']);
                let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                g.type = 'text/javascript';
                g.async = true;
                g.defer = true;
                g.src = u + 'piwik.js';
                s.parentNode.insertBefore(g, s);
            }
        }

        function initThreeQsdn() {
            console.log('init 3qsdn');
            $('.dsgvo[data-provider="threeqsdn"]').addClass('dsgvo-accepted');
        }

        function initCookiebanner() {
            // default: all categories disabled
            let consentCookie = Object.assign({system: false, functional: false, statistic: false}, getConsentCookie());

            // show cookie if not accepted already
            if (!consentCookie.system) $('.ckb').show();

            // activate providers by traversing the consentCookie
            Object.keys(consentCookie).forEach(function (providerType) {					  				// iterate through all types in cookie [system, functional, statistic]
                Object.keys(consentCookie[providerType]).forEach(function (providerName) {	  			// iterate through all providers in cookie
                    if (consentCookie[providerType][providerName] && providers[providerType][providerName]) {	// is enabled? @example: cookie.functional.youtube === true
                        providers[providerType][providerName].init();											// execute its `init()`-function if provider is found
                    }
                    providers[providerType][providerName].enabled = !!consentCookie[providerType][providerName];
                });
            });
        }

        // these are the external providers and their init
        // functions that are activated via consent cookie
        providers = {
            system: {},
            statistic: {
                matomo: {
                    enabled: false,
                    init: initMatomo,
                    destroy: function () {
                    },
                },
            },
            functional: {
                youtube: {
                    enabled: false,
                    init: initYouTube,
                    destroy: function () {
                    },
                },
                twitter: {
                    enabled: false,
                    init: function () {
                        console.log('init twitter'); // ???
                    },
                    destroy: function () {
                    },
                },
                threeqsdn: {
                    enabled: false,
                    init: initThreeQsdn,
                    destroy: function () {
                    },
                },
            },
        };

        initCookiebanner();

        // cookie panel control on privacy page
        if ($('.ck-panel').length) {

            // check all children if parents are checked
            $('.ck-panel .cookie-provider').each(function () {
                let providerName = $(this).attr('data-cookie-provider');
                let providerType = $(this).attr('data-cookie-type');

                if (providerType && providerName && providers[providerType][providerName]) {
                    $(this).prop('checked', providers[providerType][providerName].enabled); // set checkbox state according to provider state
                }
            });

            // if all children are checked, check parents too
            $('.ck-panel .cookie-group').each(function () {
                let allChildrenChecked = $(this).find('.cookie-provider[type=checkbox]:not(:checked)').length === 0; // if no checkbox is unchecked .. all are checked
                $(this).find('.cookie-group-toggle').prop('checked', allChildrenChecked); // do not trigger 'change' because it will trigger above function and check all children to the same value
            });

            // sync checkboxes
            $('[data-sync-checkbox]')
                .each(function () {
                    let target = $(this).attr('data-sync-checkbox');
                    let targetState = $(target).prop('checked');

                    $(this)
                        .prop('checked', targetState)   // set initial state
                        .on('change', function () {		// on change set target checked to this.checked
                            $(target)
                                .prop('checked', $(this).prop('checked'))
                                .trigger('change');
                        });
                });
        }
    });

    let providers;

    // ### jQuery
    // ### cookie panel in legal/impressum

    // toggle all children checkboxes
    $('.cookie-group-toggle').on('change', function () {
        let state = $(this).prop('checked');
        let $parent = $(this).closest('.cookie-group');
        $parent
            .find('.cookie-provider[type=checkbox]')
            .each(function () {
                $(this)
                    .prop('checked', state)
                    .trigger('change');
            });
    });

    // save to consent cookie
    $('[data-save-cookie-preferences]').on('click', function (e) {
        e.preventDefault();
        $('.cookie-provider').each(function () {
            let state = $(this).prop('checked');
            let providerName = $(this).attr('data-cookie-provider');
            let providerType = $(this).attr('data-cookie-type');

            updateConsentCookie(providerType, providerName, state);
        });

        location.reload();
    });

});
