import $ from 'jquery';
import moment from 'moment';
import localeDe from 'moment/locale/de';
import SimpleBar from "simplebar";

// set moment locale globally de
moment.updateLocale('de', localeDe);

export function initTwitterProxy() {
    let $twitterProxy = $('.twitter-proxy');
    let $twitterProxyPlaceholder = $('.twitter-proxy-placeholder', $twitterProxy);
    if (!$twitterProxyPlaceholder.length) {
        //console.log('no twitter proxy container found');
        return;
    }

    /**
     * Build Link Element for Twitter Proxy
     * @param {string} href
     * @param {string} linkText
     * @param {string} title
     * @returns {string}
     */
    const buildLink = function (href, linkText, title) {
        return '<a target="_blank" title="' + title + '" href="' + href + '">' + linkText + '</a>';
    };

    /**
     * returnst text without shortened text links and replaces
     * it with actual link and display-url
     *
     * @param {string} text
     * @param {object} url
     * @param {boolean} removeCompletely
     * @returns {string}
     */
    const stripLink = function (text, url, removeCompletely) {
        let link = 'undefined' !== typeof removeCompletely && removeCompletely ? buildLink(url.expanded_url, url.display_url, url.expanded_url + ' in neuem Fenster öffnen') : '';
        return text.replace(url.url, link);
    };

    /**
     *
     * @param  {string} time
     * @returns {string}
     */
    const getRelativeTime = function (time) {
        return moment(time).fromNow();
    };

    /**
     * Enables SimpleBar after async loading
     * @param $el
     * @returns {boolean}
     */
    const initSimepleBar = function ($el) {
        if ('undefined' == typeof SimpleBar) {
            console.error('simpleBar missing');
            return false;
        } else {
            $($el).each(function () {
                let sb = new SimpleBar($(this).find('.simple-bar')[0]);
            });
        }
    };

    // get twitter timeline asynchrounously
    // screen_name=Brandenburg_LT
    let twitterUser = 'Brandenburg_LT';
    let encodedProxyUri = encodeURIComponent('statuses/user_timeline.json?screen_name=' + twitterUser + '&count=5&tweet_mode=extended');
    $.getJSON('/sixcms/detail.php?template=ltbrb_twitter_proxy_d&url=' + encodedProxyUri, function (responseJson) {
        let template = '';

        responseJson.map(function (tweet) {
            let text = tweet.full_text;

            // Antworten des Inhabers nicht anzeigen
            if (!tweet.in_reply_to_status_id) {

                // strip short urls for image links
                if ('undefined' !== typeof tweet.entities.media) {
                    tweet.entities.media.slice(0).reverse().map(function (media) {
                        text = stripLink(text, media);
                    });
                }

                // strip short urls and collect them as links
                if ('undefined' !== typeof tweet.entities.urls) {
                    tweet.entities.urls.slice(0).reverse().map(function (url) {
                        text = stripLink(text, url, true);
                    });
                }

                // replace newlines with `<br>` tag
                text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

                // collect tweets
                template += ''
                    + '<li class="tweet">'
                    + ' <a target="_blank" href="https://twitter.com/' + tweet.user.screen_name + '/status/' + tweet.id_str + '" class="meta-info"><strong>@' + tweet.user.screen_name + '</strong> &ndash; ' + getRelativeTime(tweet.created_at) + '<br></a>'
                    + text
                    + '</li>';

            }
        });

        $twitterProxyPlaceholder.html(template);
        initSimepleBar($twitterProxy);
    }).fail(function () {
        $twitterProxyPlaceholder.html('Fehler beim Laden der Tweets.');
    });

}
